// stylelint-disable selector-max-type

// variables ###################################################################
// #############################################################################

$typography-font-family: "DM Sans", sans-serif;
$typography-font-size: 16px;
$typography-font-weight: 400;
$typography-font-line-height: 24px;
$typography-color-text: $grayscale90;

// main ########################################################################
// #############################################################################

body {
  font-family: $typography-font-family;
  font-size: $typography-font-size;
  line-height: $typography-font-line-height;
  color: $typography-color-text;
  font-weight: $typography-font-weight;
  text-size-adjust: 100%;
}
