
          @import
            "@/assets/scss/variables.scss",
            "@/assets/scss/mixins.scss";
        

.vue-notification {
  font-family: $typography-font-family;

  &-group {
    z-index: $z-index-notification !important;
  }
}
