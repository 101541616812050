
          @import
            "@/assets/scss/variables.scss",
            "@/assets/scss/mixins.scss";
        

.header {
  position: relative;
  z-index: $z-index-header;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: $header-height;
  margin: 0 auto;

  &.is--open-mobile-menu {
    box-shadow: $small-shadow;
  }

  &__right {
    display: flex;
    align-items: center;
  }

  &__logo-menu-wrap {
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }

  &__nav {
    display: flex;
    align-items: center;
  }

  &__bell {
    position: relative;
    display: none;
    align-items: center;
    margin-right: 15px;
    @include media-lte(desktop-md) {
      display: flex;
    }
  }

  &__bell-count {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -8px;
    left: 12px;
    width: 18px;
    height: 18px;
    font-size: 11px;
    color: $color-white;
    border-radius: 100%;
    background-color: $color-valencia;
    z-index: 2;
  }
}

.header-logo {
  display: flex;
  align-items: center;

  @include media-lte(desktop-lg) {
    max-width: 220px;
    margin-right: 30px;
  }
}

.header-menu {
  align-items: center;
  transition: all 0.3s;

  @include media-lte(desktop-md) {
    display: none;
  }
}

.header-nav {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 24px;

  @include media(tablet) {
    font-size: 16px;
  }

  &__left,
  &__right {
    display: flex;
    align-items: center;
  }

  &__item {
    color: $grayscale90;

    &:not(:last-of-type) {
      margin-right: 60px;
      @include media-lte(desktop-lg) {
        margin-right: 30px;
      }
    }
    @include media-lte(desktop-md) {
      display: none;
    }

    &.is--active {
      text-decoration: underline;
    }
  }

  &__item-link {
    color: inherit;
  }
}

.header-btns {
  min-height: 34px;

  &__sign-up {
    margin-left: 12px;
  }

  @include media-lte(desktop-md) {
    display: none;
  }


  &__profile {
    @include media-lte(desktop-md) {
      display: none;
    }
  }
}
