
          @import
            "@/assets/scss/variables.scss",
            "@/assets/scss/mixins.scss";
        


.header-menu-bg,
.header-burger,
.mobile-menu {
  @include media-gt(desktop-md) {
    display: none !important;
  }
}

.header-menu-bg {
  position: fixed;
  top: $header-height;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $z-index-menu-bg;
  width: 100%;
  height: 100%;
  visibility: hidden;
  background: rgba(0, 0, 0, 0.33);
  opacity: 0;
  transition: 0.3s;

  &.is-active {
    visibility: visible;
    opacity: 1;
  }
}

.mobile-menu {
  position: fixed;
  top: $header-height;
  right: 0;
  z-index: $z-index-menu;
  display: flex;
  flex-direction: column;
  width: 241px;
  height: calc(100% - $header-height);
  margin-right: 0;
  background: $color-white;
  opacity: 0;
  overflow-y: hidden;
  transition: opacity 0.3s, transform 0.3s;
  transform: translateX(110%);

  &__item {
    padding: 14px 0;
    display: block;
    color: $grayscale90;

    &.is--padding {
      padding-left: 12px;
      padding-right: 12px;
    }

    &.is--active {
      color: $accent;
    }
  }

  &__item-link {
    color: inherit;
  }

  &__header-shadow {
    width: 100%;
    height: 10px;
    position: fixed;
    top: -10px;
    z-index: $z-index-menu;
    box-shadow: $small-shadow;
  }

  &.is-active {
    opacity: 1;
    transform: translateX(0);
  }

  &__list {
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: baseline;
    min-height: calc(100% - $header-height);
  }

  &__header-wrapper {
    padding-right: 21px;
    background: #152c76;
  }

  &__btns {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    align-items: center;
    padding: 0 12px;
    width: 100%;
    margin-bottom: 48px;
  }

  &__auth {
    width: 100%;
    & + & {
      margin-top: 24px;
    }
  }

  &__divider {
    width: 100%;
    margin-right: 15px;
    border-top: 2px solid #2244a8;
  }

  &__social-links-wrapper {
    padding: 5px 27px 15px 20px;
    margin-top: auto;
    font-size: 12px;
    font-weight: 600;
    color: $color-green-vogue;
    > span {
      color: $color-text-black;
      font-size: 14px;
    }
  }

  &__btn {
    & + & {
      margin-top: 18px;
    }
  }

  &__arrow {
    height: 15px;
  }

  &__login {
    &-wrap {
      padding: 0 31px 15px;
      text-align: center;
    }

    &-icon {
      width: 72px;
      margin: 23px 0 10px;
    }
  }

  &__menu {
    width: 100%;
  }

  &__profile {
    background: $accent20;
    padding: 12px;
    display: flex;
    align-items: center;
    margin-bottom: 13px;
  }

  &__profile-wrap {
    margin-right: 12px;
  }

  &__profile-value{
    color: $grayscale70;
  }

  &.is--logged-in {
    .mobile-menu__nav {
      padding-top: 13px;
      border-top: 1px solid $grayscale30;
      margin-top: 14px;
    }
  }

  &__nav {
    margin: 0 12px;
  }
}

.header-burger {
  z-index: $z-index-menu-burger;
  margin-left: 19px;
  color: $color-text-header;
  cursor: pointer;
  transition: color 0.3s;

  @include media-lt(tablet) {
    margin: 0 0 0 15px;
  }

  &__btn {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  &.is-close {
    color: $accent;
  }

  &__icon {
    color: $accent;
    width: 24px;
    height: auto;
    &.is-close {
      width: 17px;
    }
  }
}
