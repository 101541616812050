@font-face {
  font-family: sans-serif;
  src: local('sans-serif');
  ascent-override: 110%;
  descent-override: 30%;
  line-gap-override: 8%;
}

@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("@/assets/fonts/DMSans-Bold.woff2") format("woff2"),
    url("@/assets/fonts/DMSans-Bold.woff") format("woff");
}

@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("@/assets/fonts/DMSans-Medium.woff2") format("woff2"),
    url("@/assets/fonts/DMSans-Medium.woff") format("woff");
}

@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("@/assets/fonts/DMSans-Regular.woff2") format("woff2"),
    url("@/assets/fonts/DMSans-Regular.woff") format("woff");
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("@/assets/fonts/Montserrat-Bold.woff2") format("woff2"),
    url("@/assets/fonts/Montserrat-Bold.woff") format("woff");
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("@/assets/fonts/Montserrat-Regular.woff2") format("woff2"),
    url("@/assets/fonts/Montserrat-Regular.woff") format("woff");
}
