// stylelint-disable selector-max-type

html,
body,
#app {
  height: 100%;
  min-height: 100%;
}

body {
  scroll-behavior: smooth;
  &::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 8;
    background-color: rgba(51, 51, 51, 0.2);
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease;
  }
  &.is-notification-sidebar-open {
    &::before {
      opacity: 1;
      visibility: visible;
    }
  }
}

p {
  margin-bottom: 16px;
}

img {
  max-width: 100%;
}

.wd-page-title {
  position: relative;
  font-size: 44px;
  font-style: normal;
  text-transform: uppercase;
  font-weight: 900;
  line-height: 62px;
  color: $color-text;
  letter-spacing: 1.5px;
  padding-bottom: 45px;
  @include media-lte(tablet) {
    font-size: 32px;
    line-height: 47px;
    padding-bottom: 20px;
  }
  &::before {
    content: "";
    position: absolute;
    bottom: 30px;
    left: 0;
    width: 48px;
    height: 2px;
    background-color: $color-blue-14;
    @include media-lte(tablet) {
      font-size: 26px;
      line-height: 40px;
      bottom: 10px;
    }
  }
  &.wd-page-title-center {
    text-align: center;
    &::before {
      margin: 0 auto;
      right: 0;
    }
  }

  &.wd-page-title-md {
    font-size: 34px;
    line-height: 52px;
    text-transform: none;
  }
}

.wd-page-sub-title {
  font-size: 20px;
  line-height: 32px;
  letter-spacing: normal;
  font-weight: 300;
}

.text-uppercase {
  text-transform: uppercase;
}

a {
  text-decoration: none;
  cursor: pointer;
  transition: all 0.2s;
  color: $grayscale90;
  &:hover {
    text-decoration: underline;
  }
}

h1, .is--h1 {
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
}

h2, .is--h2 {
  font-weight: 700;
  font-size: 40px;
  line-height: 56px;
}

h3, .is--h3 {
  font-weight: 700;
  font-size: 36px;
  line-height: 50px;
}

h4, .is--h4 {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
}

h5, .is--h5 {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
}

h6, .is--h6 {
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
}

.is--subheading {
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
}

.is--body-text-1 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.is--body-text-1-bold {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.is--body-text-2 {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.is--button {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.is--small-text-1 {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.is--small-text-2 {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

.is--hero {
  font-weight: 700;
  font-size: 60px;
  line-height: 80px;
  @include media-lt(tablet) {
    font-weight: 700;
    font-size: 40px;
    line-height: 56px;
  }
}

.is--display {
  font-family: Montserrat, sans-serif;
  font-size: 96px;
  font-style: normal;
  font-weight: 800;
  line-height: 112px;
  @include media-lt(tablet) {
    font-weight: 700;
    font-size: 40px;
    line-height: 56px;
  }
}

.is--montserrat {
  font-family: Montserrat, sans-serif;
}

.is--two-col {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
/* stylelint-disable */
  & > li {
    width: 50%;
  }
}

.is--doc-list {
  ul,
  ol {
    margin-left: 12px;
  }
}

.is--flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.is--numbered {
  list-style: decimal;
  list-style-position: inside;

  li + li {
    margin-top: 15px;
  }
}

.is--underline {
  text-decoration: underline;
}

.is--spots {
  list-style: disc;
  list-style-position: inside;
}

.is--upper-alpha {
  list-style: upper-alpha;
  list-style-position: inside;
  margin-bottom: 10px;

  li + li {
    margin-top: 15px;
  }
}

.is--readonly {
  pointer-events: none;
}
