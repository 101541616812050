.wd-100h {
  height: 100%;
}

.wd-100w {
  width: 100%;
}

.wd-font-bold {
  font-weight: 600;
}

.wd-font-bolder {
  font-weight: 700;
}

.wd-link {
  display: inline-flex;
  padding-bottom: 10px;
  font-size: 15px;
  line-height: 15px;
  text-decoration: none;
  letter-spacing: 0.01em;
  border-bottom: 2px solid transparent;

  &.is-general {
    border-bottom: 2px solid rgba($color-text, 0.5);
  }

  &.router-link-active {
    &::before {
      pointer-events: none;
      visibility: visible;
      opacity: 1;
    }

    &:hover {
      color: $color-text;
      border-bottom: none;
      opacity: 1;

      &::before {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  &.router-link-exact-active {
    pointer-events: none;
  }
}

.hidden-on-desktop {
  @include media-gt(tablet) {
    display: none;
  }
}

.hidden-on-tablet {
  @include media-lte(tablet) {
    display: none;
  }
}
