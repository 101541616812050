
          @import
            "@/assets/scss/variables.scss",
            "@/assets/scss/mixins.scss";
        

.logo {
  display: flex;
  align-items: center;
  padding-bottom: 0;
  border-bottom: $unset-value;
  text-decoration: none;

  &__desktop {
    width: 250px;
    height: auto;
  }

  &__text {
    font-size: 12px;
    font-weight: 400;
    color: $grayscale50;
    text-decoration: none;
    margin-top: 10px;
    margin-left: 4px;
  }
}

