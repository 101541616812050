/** grayscale **/
$grayscale-white: #FFFFFF
$grayscale5: #FAFAFA
$grayscale10: #F4F5F6
$grayscale20: #EDEDEF
$grayscale30: #CFD2D7
$grayscale50: #868B90
$grayscale60: #61666F
$grayscale70: #444850
$grayscale90: #222529
$grayscale100: #141416

/** accent **/
$accent: rgba(0, 1, 44, 1)
$accent20: rgba(0, 1, 44, 0.2)
$accent40: rgba(0, 1, 44, 0.4)
$accent60: rgba(0, 1, 44, 0.6)
$accent80: rgba(0, 1, 44, 0.8)
$accentHovered: rgba(0, 1, 44, 1)

/** utility **/
$utilityGreenLight: #E6FDCF
$utilityGreenDark: #3C8F65
$utilityYellow: #FFF8B5
$utilityPurple: #736882
$utilityBlue: #4169E1
$utilityRed: #FF543D

/** secondary Pink **/
$secondaryPink: #F5DCFC
$secondaryPink80: rgba(255, 0, 191, 0.8)
$secondaryPink60: rgba(255, 0, 191, 0.6)
$secondaryPink40: rgba(255, 0, 191, 0.4)
$secondaryPinkHover: rgba(255, 140, 226, 1)
$pinkLighter: #FAE9FF
$pinkDarker: #D9C2E0

/** secondary Peach **/
$secondaryPeach: #FF543D
$secondaryPeach80: rgba(255, 163, 120, 0.8)
$secondaryPeach60: #FFC7AC
$secondaryPeach40: rgba(255, 163, 120, 0.4)
$secondaryPeach20: rgba(255, 163, 120, 0.2)

$darkPurple: #00012C