
          @import
            "@/assets/scss/variables.scss",
            "@/assets/scss/mixins.scss";
        

// stylelint-disable scale-unlimited/declaration-strict-value

.base-button {
  $root: &;

  --base-button__icon-width: 20px;
  --base-button__icon-height: 20px;

  // general type button
  &:not(.is--icon) {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 45px;
    padding: 12px 20px;
    border-radius: 30px;
    color: $grayscale-white;
    text-decoration: none;
    cursor: pointer;
    background: $accent;
    border: none;
    outline: none;
    transition: all ease-out 0.3s;

    &:hover {
      text-decoration: none;
      background: $accent80;
      color: $grayscale-white;
    }

    &:active {
      background: $accent80;
    }

    &.is--active {
      background: $accent80;
    }
  }

  &__inner {
    z-index: 1;
    pointer-events: none;
    display: flex;
    align-items: center;
  }

  &.is--small {
    height: 34px;
    font-size: 12px;
    line-height: 18px;
    padding: 8px 12px;
  }

  &.is--disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.3;
  }

  &.is--toggle-active {
    background: $accentHovered !important;
    color: $grayscale-white !important;
  }

  &.is--block {
    width: 100%;
  }

  &.is--icon {
    display: inline-flex;
    padding: 8px;
    align-items: flex-start;
    border-radius: 30px;
    flex-shrink: 0;
    color: $accent;
    cursor: pointer;
    border: none;
    outline: none;
    transition: all 0.25s;

    &:hover {
      background: $accent20;
    }

    &.is--disabled {
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.3;
      background: $grayscale10;
    }

    &.is--secondary {
      border: 1px solid $accent;

      &:hover {
        background: $accent;
        color: $grayscale-white;
      }
    }
  }

  &__icon {
    display: flex;
    width: var(--base-button__icon-width);
    height: var(--base-button__icon-height);
    justify-content: center;
    align-items: center;
  }

  &.is--navigation-prev,
  &.is--navigation-next {
    padding: 8px 12px;

    &:hover {
      #{$root}__nav-icon {
        color: $grayscale-white;
      }
    }
  }

  &__nav-icon {
    color: $accent;
    flex-shrink: 0;
    height: 10px;
    transition: all 0.25s;
  }

  &.is--navigation-prev {
    #{$root}__nav-icon {
      margin-right: 10px;
      margin-left: 3px;
    }
  }

  &.is--navigation-next {
    #{$root}__nav-icon {
      margin-left: 6px;
      margin-right: -8px;
    }
  }

  // type loading
  &.is--loading {
    pointer-events: none;

    #{$root}__inner {
      opacity: .4;
    }

    #{$root}__loader {
      position: absolute;
      z-index: 3;
      display: block;
      width: 25px;
      height: 25px;
      content: "";
      border: 3px solid #6095ff;
      border-width: 3px;
      border-top-color: white;
      border-radius: 50%;
      animation: spin 0.8s linear infinite;
    }
  }

  &:not(.is--icon).is--secondary {
    color: $accent;
    background: $grayscale-white;
    border: 1px solid $accent;

    &:hover {
      color: $grayscale-white;
      background: $accent;
    }

    &:active {
      color: $grayscale-white;
      background: $accent80;
    }

    &.is--active {
      color: $grayscale-white;
      background: $accent80;
    }
  }

  &:not(.is--icon).is--tetriary {
    color: $accent;
    background: $accent20;

    &:hover {
      background: $accent40;
    }

    &:active {
      background: $accent60;
    }

    &.is--active {
      background: $accent60;
    }
  }

  &:not(.is--icon).is--ghost {
    color: $accent;
    background: transparent;

    &:hover {
      background: $accent20;
    }

    &:active {
      background: $accent60;
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
