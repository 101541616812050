
          @import
            "@/assets/scss/variables.scss",
            "@/assets/scss/mixins.scss";
        

.header-card {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 33px;
  font-size: 17px;
  font-weight: 400;
  line-height: 19px;
  color: $color-text-header;
  transition: all 0.2s;

  &:hover {
    cursor: pointer;
  }

  &.is--loading {
    pointer-events: none;
  }
}
