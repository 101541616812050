@import 'color';

// colors ######################################################################
// #############################################################################

$color-backdrop: rgba(0, 0, 0, 0.5);
$color-white: #fff;
$color-card: $color-white;
$color-text-black: #333;
$color-text-black-1: #101010;
$color-text-black-2: #1d1d1d;
$color-soft-gray: #798dca;
$color-green: #00d395;
$color-red: #ff5252;
$color-red-light: #f7e7e8;

$color-body: #515d69;
$color-limed-spruce: #3d4751;
$color-green-vogue: #042751;
$color-tundora: #424242;
$color-secondary: #2e74ff;
$color-primary: #2c2c2c;
$color-mine-shaft: #3b3b3b;
$color-sirocco: #797a7a;
$color-biscay: #1e3068;
$color-alabaster: #f7f7f7;
$color-white-hover: #020202;
$color-disabled: #fff3f3;
$color-yellow: #fdd553;
$color-yellow-light: #fff082;
$color-yellow-link: #fdf8e6;
$color-sapphire: #060d5d;
$color-midnight-express: #011044;
$color-light-sea-green: #24c0b3;
$color-royal-blue: #407bff;
$color-cerulean-blue: #3664c2;
$color-havelock-blue: #6882d4;
$color-free-speach-blue: #4d68ba;
$color-tory-blue: #28418e;
$color-tory-blue-1: #2c4597;
$color-dark-turquoise: #01d8dc;
$color-dark-blue: #08143e;
$color-solitude: #d7d7d7;
$color-caribbean-green: #00d395;
$color-tahiti-gold: #e4761b;
$color-water-leaf: #a9ffe6;
$color-valencia: #da564e;

$color-gray: gainsboro;
$color-grey-0: #f0f3f9;
$color-gray-1: #9fb1e8;
$color-gray-3: #999;
$color-gray-4: #2d3954;
$color-gray-5: #e8ebf4;
$color-gray-6: #f7faff;
$color-gray-7: #d4dce6;
$color-gray-8: #dce2f3;
$color-gray-9: #e4e9f7;
$color-gray-10: #dfe5f1;
$color-gray-11: #dce4f3;
$color-gray-12: #5C5C5C;
$color-gray-13: #9c9c9c;

$color-blue-1: #3f63d4;
$color-blue-2: #314ca2;
$color-blue-3: #3854ab;
$color-blue-4: #739efa;
$color-blue-5: $color-soft-gray;
$color-blue-6: #415aad;
$color-blue-7: #29418f;
$color-blue-8: #4065d8;
$color-blue-9: #d2e5ff;
$color-blue-10: #f0f8ff;
$color-blue-11: #102461;
$color-blue-12: #1a327c;
$color-blue-13: #274191;
$color-blue-14: #004fff;
$color-blue-15: #6f91ff;
$color-blue-16: #3d61d1;
$color-blue-17: #1e57ca;
$color-blue-18: #E3ECFF;

$color-orange-1: #da914e;
$color-orange-2: #fbf4ed;

$color-normal: #37f;
$color-warning: #fad61d;
$color-warning-notification: #ff5252;
$color-danger: #ff6f1e;
$color-critical: #ff5252;

$color-dodger-blue: #33b6ff;
$color-accent: #00c9d4;
$color-accent-2: #01d7dc;
$color-accent-3: #01cad5;
$color-switch-bg: #3f58a8;
$color-switch-disabled: #5269b4;

$color-pink-dark: #1b1a56;
$color-pink-light: #704a87;
$color-pink-card: rgba(56, 21, 62, 0.45);
$color-pink-gradient: linear-gradient(270deg, rgba(255, 82, 82, 0.12) 0%, rgba(255, 82, 82, 0.06) 100%);

// breakpoints #################################################################
// #############################################################################

$breakpoints: (
  mobile: 320px,
  mobile-xs: 374px,
  tablet-xs: 575px,
  tablet: 768px,
  desktop: 980px,
  desktop-md: 1024px,
  desktop-lg: 1199px,
  wide: 1366px
);

// typography ##################################################################
// #############################################################################

$typography-font-family: "DM Sans", sans-serif;
$typography-font-size: 16px;
$typography-font-weight: 400;
$typography-font-line-height: 24px;
$typography-color-text: $grayscale90;

// z-index layers ##############################################################
// #############################################################################

$z-index-tooltip: 12000;
$z-index-modal: 10000 + 1000;
$z-index-notification: $z-index-modal + 1000;
$z-index-loader-global: $z-index-notification + 1000;
$z-index-footer: 90;
$z-index-header: 1000;
$z-index-menu-bg: 99;
$z-index-menu: 999;
$z-index-menu-burger: $z-index-menu + 1;

// utils #######################################################################
// #############################################################################

$transition-duration: 0.5s !default;
$transition: $transition-duration ease !default;

$transition-duration-fast: 0.25s !default;
$transition-fast: $transition-duration-fast ease !default;

$radius: 4px !default;

$box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.2);

$header-height: 75px;
$footer-height: 501px;
$unset-value: unset;

$color-text: $color-text-black;
$color-text-grey: $color-gray-3;
$color-text-header: $color-text-black;
$color-modal-bg: $color-white;

$small-shadow: 0 2px 5px -2px rgba(0, 1, 44, 0.30);
$medium-shadow: 0 3px 15px -2px rgba(0, 1, 44, 0.20);
$large-shadow: 0 15px 60px 5px rgba(0, 1, 44, 0.20);


