
          @import
            "@/assets/scss/variables.scss",
            "@/assets/scss/mixins.scss";
        

.cookie-consent {
  position: fixed;
  bottom: 30px;
  z-index: 999;
  box-shadow: $medium-shadow;
  background: $accent40;
  border-radius: 20px;
  border: 1px solid $accent20;

  @media screen and (min-width: 768px) {
    transform: translate(-50%, 0);
    left: 50%;
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    width: calc(100% - 30px);
    bottom: 15px;
    left: 15px;
    max-width: 100% !important;
  }

  &__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 15px;
    border-radius: 2px;
    @media screen and (max-width: 767px) {
      padding: 10px;
      flex-direction: column;
      justify-content: center;
    }
  }

  &__text {
    margin: 0;
    @media screen and (max-width: 767px) {
      margin-bottom: 5px;
      text-align: center;
    }
  }

  &__buttons {
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;

    @media screen and (max-width: 767px) {
      margin-left: 0;
    }
  }

  &__button {
    flex-shrink: 0;
    & + & {
      margin-left: 5px;
    }
  }
}
.fade-change-leave-active {
  transition: transform 0.3s ease-in-out;
}
.fade-change-enter-active {
  transition: opacity 0.4s ease-in-out 0.3s;
}
.fade-change-enter-to {
  opacity: 1;
}
.fade-change-enter-from,
.fade-change-leave-to {
  opacity: 0;
}
