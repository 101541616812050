
          @import
            "@/assets/scss/variables.scss",
            "@/assets/scss/mixins.scss";
        

.app-layout-default {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  min-height: 100%;
  padding-top: $header-height;
  background: $secondaryPink;

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 100;
    background: transparent;
    transition: all .12s ease-in-out;

    &.fixed {
      position: fixed;
      background: $grayscale-white;
      box-shadow: $small-shadow;
    }

    @include media-lte(desktop-md) {
      background: $grayscale-white;
      box-shadow: $small-shadow;
    }
  }

  &__main {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    width: 100%;
  }

  &__footer {
    width: 100%;
    position: relative;
    z-index: $z-index-footer;
    padding-top: 150px;
    min-height: $footer-height;
    @include media-lte(tablet) {
      padding-top: 100px;
    }
  }

  &__footer-item {
    margin-bottom: 50px;
  }
}
