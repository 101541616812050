
          @import
            "@/assets/scss/variables.scss",
            "@/assets/scss/mixins.scss";
        

.footer {
  padding: 48px 48px 32px 48px;
  border-radius: 30px;
  background: $pinkLighter;
  max-width: 1146px;
  margin: 0 auto;
  min-height: 300px;
  @include media-lte(tablet) {
    padding: 32px 24px;
  }

  &__top {
    border-bottom: 1px solid $grayscale30;
    padding-bottom: 31px;
    gap: 20px;
    @include media-gt(tablet) {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
  }

  &__logo {
    margin-bottom: 16px;
  }

  &__description {
    color: $grayscale90;
    margin-bottom: 32px;
    @include media-lte(tablet) {
      margin-bottom: 24px;
    }
  }

  &__contact {
    display: block;
    margin-bottom: 16px;
    color: $accent;
    text-decoration: underline;
    @include media-lte(tablet) {
      margin-bottom: 29px;
    }
  }

  &__copyright {
    color: $grayscale60;
    margin-bottom: 0;
  }

  .footer-nav {
    @include media-gt(tablet) {
      display: flex;
      flex-direction: row;
      justify-content: end;
      flex-wrap: wrap;
      gap: 15px;
    }
    @include media-lte(tablet) {
      margin-bottom: 31px;
    }

    &__item {
      flex-shrink: 0;

      @include media-gt(tablet) {
        width: 30%;
        flex-shrink: 0;
      }
      @include media-lte(tablet) {
        margin-bottom: 21px;
      }
    }
  }

  &__social {
    @include media-gt(tablet) {
      justify-content: flex-end;
    }
  }

  &__terms-item {
    color:$accent;
    text-decoration: underline;
    min-width: 70px;

    & + & {
      margin-left: 45px;
      @include media-lte(tablet) {
        margin-left: 26px;
      }
    }
  }

  &__terms {
    min-height: 18px;
  }
}
