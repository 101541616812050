// stylelint-disable selector-max-type

html {
  text-size-adjust: 100%;
  /* stylelint-disable-next-line property-no-unknown */
  font-smooth: grayscale;
  text-rendering: optimizelegibility;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}


/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
    &::before,
    &::after {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }
}

/* Remove default margin */
html, body, h1, h2, h3, h4, p, figure, blockquote, dl, dd {
  margin: 0;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizespeed;
  line-height: 1;
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

iframe,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
code,
em,
sub,
sup,
ol,
ul,
li,
fieldset,
label,
button,
nav {
  padding: 0;
  margin: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
  border: 0;
}

input {
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type="number"] {
    appearance: textfield;
  }
}

/* Inherit fonts for inputs and buttons */
input, button, textarea, select {
  font: inherit;
}

/* Remove list styles */
ol, ul {
  list-style: none;
}

ul {
  margin: 0;
}

blockquote, q {
  quotes: none;

  &::before,
  &::after {
    content: "";
    content: none;
  }
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

/* Make images easier to work with */
img, svg, picture {
  max-width: 100%;
  display: block;

  &:focus {
    outline: none;
  }
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  /* stylelint-disable */
  -webkit-appearance: none;
  /* stylelint-enable */
}
