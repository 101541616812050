
          @import
            "@/assets/scss/variables.scss",
            "@/assets/scss/mixins.scss";
        

.default-avatar {
  position: relative;
  display: flex;
  width: 34px;
  height: 34px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  overflow: hidden;
  background: rgba(86, 63, 200, 0.20);

  &__icon {
    color: $accent;
    text-transform: uppercase;
  }

  &__image {
    width: 100%;
  }
}
