
          @import
            "@/assets/scss/variables.scss",
            "@/assets/scss/mixins.scss";
        

.loader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $z-index-loader-global;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #fff;

  &__circle {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 120px;
  }

  &__wrap {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__logo {
    width: 300px;
  }
}
