
          @import
            "@/assets/scss/variables.scss",
            "@/assets/scss/mixins.scss";
        

.notification-sidebar {
  position: fixed;
  right: 0;
  top: 64px;
  height: calc(100% - 64px);
  z-index: 9;
  width: 413px;
  background: $color-white;
  box-shadow: 0 1px 15px 0 rgba(0,0,0,.05);
  @include media-lte(tablet) {
    width: 320px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-text-black-2;
    color: $color-white;
    text-align: center;
    padding: 10px;
    height: 50px;
    width: 100%;
  }

  &__close-icon {
    position: absolute;
    right: 20px;
    top: 19px;
    z-index: 2;
    cursor: pointer;
  }

  &__content {
    padding: 20px 15px 100px 15px;
  }

  &__item {
    margin-bottom: 20px;
    padding-bottom: 12px;
    border-bottom: 1px solid $color-solitude;
    p {
      font-weight: 300;
      font-size: 14px;
      line-height: 24px;
    }
  }

  &__item-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__item-date {
    color: $color-gray-3;
    font-size: 13px;
  }

  &__item-type {
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: .4px;
  }

  &__btn {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 15px;
    background-color: $color-white;
  }
}
