.transition-fade {
  &-enter-active,
  &-leave-active {
    transition: opacity 0.2s ease;
  }

  &-enter-from,
  &-leave-to {
    opacity: 0;
  }
}

.transition-fade-out {
  &-leave-active {
    transition: opacity 0.2s ease;
  }

  &-leave-to {
    opacity: 0;
  }
}

.transition-scale {
  &-enter-active,
  &-leave-active {
    transition: transform 0.2s ease-in;
  }

  &-enter-from,
  &-leave-to {
    transform: scale(0.4);
  }
}

.transition-translate-right {
  &-enter-active,
  &-leave-active {
    transition: transform 0.2s ease;
  }

  &-enter-from,
  &-leave-to {
    transform: translateX(100%);
  }
}

.transition-slide-fade {
  &-enter-active {
    transition: all 0.3s ease;
  }

  &-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
    height: 0;
  }

  &-enter-from,
  &-leave-to {
    transform: translateY(20px);
    opacity: 0;
  }
}
