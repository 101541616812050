
          @import
            "@/assets/scss/variables.scss",
            "@/assets/scss/mixins.scss";
        

.base-dropdown {
  $root: &;

  position: relative;

  &.is--disabled {
    pointer-events: none;
    cursor: not-allowed;
  }

  &__selected {
    position: relative;
    display: flex;
    align-items: center;
    padding: 6px 12px;
    cursor: pointer;
    border-radius: 12px;
    border: 1px solid $grayscale20;
    background: $grayscale10;
    color: $accent;
    transition: all 0.3s ease;

    &.is--active {
      .base-dropdown {
        &__selected-arrow {
          transform: rotate(180deg);
        }
      }
    }

    &:hover,
    &.is--active {
      background: $grayscale10;
      border-color: $grayscale10;
      transition: all 0.3s ease;
    }
  }

  &__selected-arrow {
    position: absolute;
    top: auto;
    width: 9px;
    right: 12px;
    color: $accent;
    transition: all 0.3s;
    transform-origin: center;

    @include media-lt(tablet) {
      right: 9px;
    }
  }

  &__notification-count {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -2px;
    left: 18px;
    width: 18px;
    height: 18px;
    font-size: 11px;
    color: $color-white;
    border-radius: 100%;
    background-color: $color-valencia;
    z-index: 2;
  }

  &__items {
    position: absolute;
    top: calc(100% + 4px);
    z-index: 10;
    width: 100%;
    padding: 8px 0;
    background: $grayscale-white;
    color: $grayscale90;
    border-radius: 0 0 12px 12px;
    box-shadow: $small-shadow;

    #{$root}:not(.is--dropdown-center, .is--dropdown-right) & {
      left: 0;
    }

    #{$root}.is--dropdown-center & {
      left: 37px;
      transform: translateX(-50%);
    }

    #{$root}.is--dropdown-right & {
      right: 0;
    }
  }

  &__elements {
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: $color-gray-5;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background: $color-gray-3;
      border-radius: 3px;
    }
  }

  &.is--light {
    #{$root}__selected {
      background: transparent;
      color: $grayscale100;
      border: none;
    }

    #{$root}__selected-arrow {
      right: 0;
    }

    #{$root}__items {
      min-width: var(--minItemsWidth);
    }
  }
}
