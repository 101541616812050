.is--container {
  width: 100%;
  max-width: 1176px;
  padding: 0 15px;
  margin: 0 auto;
}

.is--grid-container {
  display: grid;
  gap: 36px;
  grid-template-columns: 2fr 1fr;
}

.is--row {
  --row-gap: 15px;
  --row-gap-negative: -15px;

  display: flex;
  flex: 1 1 auto;
  margin: var(--row-gap-negative);

  &:not(.is--nowrap) {
    flex-wrap: wrap;
  }

  &.is--dense {
    --row-gap: 6.5px;
    --row-gap-negative: -6.5px;
  }

  &.is--no-gap {
    --row-gap: 0;
    --row-gap-negative: 0;
  }

  .is--col-auto,
  .is--col,
  .is--col-1,
  .is--col-2,
  .is--col-3,
  .is--col-4,
  .is--col-5,
  .is--col-6,
  .is--col-7,
  .is--col-8 {
    // flex-basis: 0;
    flex-grow: 1;
    margin: var(--row-gap);
  }

  .is--col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .is--col-1 {
    width: 100%;
  }

  .is--col-2 {
    width: calc(50% - 2 * var(--row-gap));
  }

  .is--col-desc-2 {
    @include media-gt(desktop-md) {
      width: calc(50% - 2 * var(--row-gap));
    }
  }

  .is--col-3 {
    width: calc(33.3333% - 2 * var(--row-gap));
  }

  .is--col-4 {
    width: calc(25% - 2 * var(--row-gap));
  }

  .is--col-5 {
    width: calc(38% - 2 * var(--row-gap));
  }

  .is--col-6 {
    width: calc(60% - 2 * var(--row-gap));
  }

  .is--col-7 {
    width: calc(61% - 2 * var(--row-gap));
  }

  .is--col-8 {
    width: calc(70% - 2 * var(--row-gap));
  }

  .is--col-lg {
    @include media-lt(desktop-lg) {
      width: 100%;
    }
  }

  .is--col-lg-2 {
    @include media-lt(desktop-lg) {
      width: calc(50% - 2 * var(--row-gap));
    }
  }

  .is--col-md {
    @include media-lt(desktop) {
      width: 100%;
    }
  }

  .is--col-sm {
    @include media-lt(tablet) {
      width: 100%;
    }
  }
}


.is--show-gt-desktop {
  @include media-lt(desktop) {
    display: none !important;
  }
}

.is--show-lt-desktop {
  @include media-gt(desktop) {
    display: none !important;
  }
}

.is--show-gt-tablet{
  @include media-lt(tablet) {
    display: none !important;
  }
}

.is--show-lt-tablet{
  @include media-gt(tablet) {
    display: none !important;
  }
}
