/**
 * like https://git.io/JtYjk and https://git.io/JtYjT
 *
 *
 * Usage:
 *
 * @mixin media(mobile, tablet) { ... }
 * @mixin media(mobile, 500px) { ... }
 */

$breakpoints: (
  mobile: 320px,
  mobile-xs: 374px,
  tablet-xs: 575px,
  tablet: 768px,
  desktop: 980px,
  desktop-md: 1024px,
  desktop-lg: 1199px,
  wide: 1366px
) !default;

@function get-breakpoint($key) {
  @if map-has-key($breakpoints, $key) {
    @return map-get($breakpoints, $key);
  }

  @return $key;
}

@mixin media-lt($to) {
  $max-width: get-breakpoint($to) - 1px;

  @media (max-width: $max-width) {
    @content;
  }
}

@mixin media-lte($to) {
  $max-width: get-breakpoint($to);

  @media (max-width: $max-width) {
    @content;
  }
}

@mixin media-gt($from) {
  $min-width: get-breakpoint($from) + 1;

  @media (min-width: $min-width) {
    @content;
  }
}

@mixin media-gte($from) {
  $min-width: get-breakpoint($from);

  @media (min-width: $min-width) {
    @content;
  }
}

@mixin media-between($from, $to) {
  $min-width: get-breakpoint($from) + 1;
  $max-width: get-breakpoint($to) - 1;

  @media (min-width: $min-width) and (max-width: $max-width) {
    @content;
  }
}

@mixin media($from, $to: null) {
  $min-width: get-breakpoint($from);
  $max-width: $to and get-breakpoint($to) - 1;

  @if ($from and $to) {
    @media (min-width: $min-width) and (max-width: $max-width) {
      @content;
    }
  }

  @else if ($from) {
    @include media-gte($from) {
      @content;
    }
  }

  @else {
    @content;
  }
}
