
          @import
            "@/assets/scss/variables.scss",
            "@/assets/scss/mixins.scss";
        

.header-profile {
  $root: &;

  min-width: 133px;
  padding: 0 10px;

  &__icon {
    color: $accent;
  }

  &__icon-wrap {
    margin-right: 12px;
  }

  &__value {
    margin-right: 26px;
  }

  &__dropdown {
    width: 100%;
  }

  &__profile {
    padding: 11px 12px;
    display: block;
    color: $grayscale90;

    &.is--active {
      color: $accent;
    }
  }
}
